'use client';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Markdown } from 'src/tapestry/core/markdown';
import { QRCode } from 'src/components/common/QRCode';
import { RefreshCell, RefreshGrid, asSubsection, GRID_DEFAULT_CONFIG } from 'src/components/layout';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useDeeplinkUrl } from 'src/hooks/useDeeplinkUrl';
import { REFRESH_BREAKPOINTS, classNames } from 'src/utils/css';
import { APP_DOWNLOAD_QR_DEEPLINK } from 'src/utils/urls';
import { FluidText } from 'src/components/common/FluidText';
import { usePageAttributesContext } from 'src/components/contexts/PageAttributesContext';
import { translations } from './AppDownloadHero.translations';
import { qrContainer_, heading_, appDownloadAsset_, disclaimer_, cellLeft_, cellRight_ } from './AppDownloadHero.module.scss';
import { useDynamicDownloadTranslation } from '../../_helpers';

const renderersParagraph = {
  paragraph: ({ children }) => (
    <FluidText type="p" min="ws-text-lg" max="ws-text-2xl">
      {children}
    </FluidText>
  ),
};

const renderersParagraphSmall = {
  paragraph: ({ children }) => (
    <FluidText type="p" all="ws-text-small">
      {children}
    </FluidText>
  ),
};

const renderersParagraphH1 = {
  paragraph: ({ children }) => (
    <FluidText type="h1" min="ws-text-lg" max="ws-text-2xl">
      {children}
    </FluidText>
  ),
};

const AppDownloadHeroContent = ({ media, ...rest }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const { deepLinkURL = APP_DOWNLOAD_QR_DEEPLINK } = usePageAttributesContext();

  const heading = useDynamicDownloadTranslation('heading');
  const bodyLarge = useDynamicDownloadTranslation('body-large');
  const bodySmall = useDynamicDownloadTranslation('body-small');
  const disclaimer = useDynamicDownloadTranslation('disclaimer');
  const qrData = useDeeplinkUrl(deepLinkURL); // need to use useDeeplink hook here vs in parent component so we have the component context available

  const getIsH1Prop = useCallback(
    propName =>
      (propName === 'bodyLarge' && !heading && bodyLarge) ||
    (propName === 'bodySmall' && !heading && !bodyLarge && bodySmall),
    [heading, bodyLarge, bodySmall]
  );

  return (
    <RefreshGrid
      xs={{ columnGap: 0, rowGap: 0 }}
      lg={{ columnGap: GRID_DEFAULT_CONFIG.gap }}
      {...rest}
    >
      <RefreshCell md={6} className={cellLeft_}>
        <div className={qrContainer_}>
          <QRCode data={qrData} withShadow />
        </div>
        {heading && (
          <FluidText
            type="h1"
            min="ws-display-md"
            max="ws-display-xl"
            maxBreakpoint={REFRESH_BREAKPOINTS.md}
            className={heading_}
          >
            {heading}
          </FluidText>
        )}
        {bodyLarge && (
          <Markdown
            renderers={getIsH1Prop('bodyLarge') ? renderersParagraphH1 : renderersParagraph}
            source={bodyLarge}
          />
        )}
        {bodySmall && (
          <Markdown
            renderers={getIsH1Prop('bodySmall') ? renderersParagraphH1 : renderersParagraphSmall}
            source={bodySmall}
          />
        )}
        {disclaimer && <Markdown className={classNames('ws-text-sm', disclaimer_)} source={disclaimer} />}
      </RefreshCell>
      <RefreshCell md={6} className={cellRight_}>
        <div className={appDownloadAsset_}>
          {media}
        </div>
      </RefreshCell>
    </RefreshGrid>
  );
};

const AppDownloadHero = asSubsection('AppDownloadHero')(AppDownloadHeroContent);

AppDownloadHero.displayName = 'AppDownloadHero';

AppDownloadHero.propTypes = {
  media: PropTypes.shape({}),
};

export { AppDownloadHero };
